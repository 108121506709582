import React from 'react';
import './App.css';
import Header from './Components/Header';
import Footer from './Components/Footer';
import About from './Components/About';
import Resume from './Components/Resume';
function App(){

    return (
      <div className="App">
          <Header/>
          <About/>
          <Resume/>
        <Footer />
      </div>
    );
  
}

export default App;
