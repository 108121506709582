import React from 'react';
import { useTranslation } from 'react-i18next';

function About(props) {

   const { t } = useTranslation();

      var profilepic= "images/descarga.png";
   
    return (
      <section id="about">
      <div className="row">
         <div className="three columns">
            <img className="profile-pic"  src={profilepic} alt="Tim Baker Profile Pic" />
         </div>
         <div className="nine columns main-col">
            <h2>{t('menu.aboutme')} <ul className="social" >
               <li key="linkedin" style={{display:"inline"}}><a href="https://www.linkedin.com/in/carlos-osses-lizana-316b01103/"><i className="fa fa-linkedin"></i></a></li>&nbsp;&nbsp;&nbsp;
               <li key="github" style={{display:"inline"}}><a href="https://github.com/carlohi"><i className="fa fa-github"></i></a></li>
            </ul></h2>

            <p>{t('main.bio')}</p>
            <div className="row">
               <div className="columns contact-details">
                  <h2>{t('header.contactDetails')}</h2>
                  <p className="address">
						   <span>{t('main.name')}</span><br />
						   <span>{t('main.address.street')}<br />
						         {t('main.address.city')}, {t('main.address.state')} {t('main.address.zip')}
                   </span><br />
						   <span>{t('main.phone')}</span><br />
                     <span>{t('main.email')}</span>
					   </p>
               </div>
               <div className="columns download">
                  <p>
                     <a href={t('main.resumedownload')} className="button" download><i className="fa fa-download"></i>{t('header.resumebtn')}</a>
                  </p>
               </div>
            </div>
         </div>
      </div>

   </section>
    );
  
}

export default About;
