import React,{useState} from 'react';
import LanguageIcon from '@material-ui/icons/Language';
import { useTranslation } from 'react-i18next';
import Particles from 'react-particles-js';

function Header(props) {

   const { t, i18n } = useTranslation();
   const [flag, setFlag] = useState(0); //Flag = 0 English version


   function handleClick(){
      if(flag===0){
         i18n.changeLanguage('es');
         setFlag(1);
      }else{
         i18n.changeLanguage('en');
         setFlag(0);
      }  
   }
    return (

      
      <header id="home">

      <nav id="nav-wrap">

         <a className="mobile-btn" href="#nav-wrap" title="Show navigation">Show navigation</a>
	      <a className="mobile-btn" href="#home" title="Hide navigation">Hide navigation</a>

         <ul id="nav" className="nav">
            <li><a className="smoothscroll" href="#home">Home</a></li>
            <li><a className="smoothscroll" href="#about">{t('menu.about')}</a></li>
	         <li><a className="smoothscroll" href="#resume">{t('menu.resume')}</a></li>
            <li onClick={()=>handleClick()}><a className="smoothscroll" href="#home"><LanguageIcon></LanguageIcon></a></li>
         </ul>


      </nav>
      
      

      <div className="row banner">
         <div className="banner-text">
            <h1 className="responsive-headline">{t('header.iam')} {t('main.name')}.</h1>
            <h3>{t('header.occupation')} {t('header.location')} {t('main.address.city')} {t('header.location2')} <span>{t('header.occupation2')}</span>. {t('main.description')}.</h3>
            <hr />
            
         </div>
      </div>

      <p className="scrolldown">
         <a className="smoothscroll" href="#about"><i className="icon-down-circle"></i></a>
      </p>
      <Particles style={{ position: "absolute",
   height: "950px",
   minHeight: "500px",
   width: "100%",
   textAlign: "center",top:"0", left:"0",zIndex:0}} params={{
      "particles": {
          "number": {
              "value": 100
          },
          "size": {
              "value": 4
          }
      },
      "interactivity": {
          "events": {
              "onhover": {
                  "enable": true,
                  "mode": "repulse"
              },
              "onclick":{
                 "enable":true,
                 "mode":"push"
              }
          }
      }
  }}  />
   </header>
    );
  
}

export default Header;
