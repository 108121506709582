import React from 'react';
import { useTranslation } from 'react-i18next';

function Resume(props){

   const { t } = useTranslation();

    return (
      <section id="resume">

      <div className="row education">
         <div className="three columns header-col">
            <h1><span>{t('resume.educationlbl')}</span></h1>
         </div>

         <div className="nine columns main-col">
            <div className="row item">
               <div className="twelve columns">
               <div key={t('resume.education.school')}><h3>{t('resume.education.school')}</h3>
        <p className="info">{t('resume.education.degree')} <span>&bull;</span><em className="date">{t('resume.education.graduated')}</em></p>
        <p>{t('resume.education.description')}</p></div>
               </div>
            </div>
         </div>
      </div>


      <div className="row work">

         <div className="three columns header-col">
            <h1><span>{t('resume.worklbl')}</span></h1>
         </div>

         <div className="nine columns main-col">
         <div key={t('resume.work1.company')}><h3>{t('resume.work1.company')}</h3>
            <p className="info">{t('resume.work1.title')}<span>&bull;</span> <em className="date">{t('resume.work1.years')}</em></p>
            <p>{t('resume.work1.description')}</p>
        </div>
        <div key={t('resume.work2.company')}><h3>{t('resume.work2.company')}</h3>
            <p className="info">{t('resume.work2.title')}<span>&bull;</span> <em className="date">{t('resume.work2.years')}</em></p>
            <p>{t('resume.work2.description')}</p>
        </div>
        </div>
    </div>



      <div className="row skill">

         <div className="three columns header-col">
            <h1><span>{t('resume.skillslbl')}</span></h1>
         </div>

         <div className="nine columns main-col">

            <p>{t('resume.skillmessage')}
            </p>

				<div className="bars">
				   <ul className="skills">
               <li key={t('resume.skill1.name')}><span style={{width:t('resume.skill1.level')}} className={'bar-expand '+t('resume.skill1.name')} ></span><em>{t('resume.skill1.name')}</em></li>
               <li key={t('resume.skill2.name')}><span style={{width:t('resume.skill2.level')}} className={'bar-expand '+t('resume.skill2.name')} ></span><em>{t('resume.skill2.name')}</em></li>
               <li key={t('resume.skill3.name')}><span style={{width:t('resume.skill3.level')}} className={'bar-expand '+t('resume.skill3.name')} ></span><em>{t('resume.skill3.name')}</em></li>
               <li key={t('resume.skill4.name')}><span style={{width:t('resume.skill4.level')}} className={'bar-expand '+t('resume.skill4.name')} ></span><em>{t('resume.skill4.name')}</em></li>
               <li key={t('resume.skill5.name')}><span style={{width:t('resume.skill5.level')}} className={'bar-expand '+t('resume.skill5.name')} ></span><em>{t('resume.skill5.name')}</em></li>
               <li key={t('resume.skill6.name')}><span style={{width:t('resume.skill6.level')}} className={'bar-expand '+t('resume.skill6.name')} ></span><em>{t('resume.skill6.name')}</em></li>
               <li key={t('resume.skill7.name')}><span style={{width:t('resume.skill7.level')}} className={'bar-expand '+t('resume.skill7.name')} ></span><em>{t('resume.skill7.name')}</em></li>
               <li key={t('resume.skill8.name')}><span style={{width:t('resume.skill8.level')}} className={'bar-expand '+t('resume.skill8.name')} ></span><em>{t('resume.skill8.name')}</em></li>
               <li key={t('resume.skill9.name')}><span style={{width:t('resume.skill9.level')}} className={'bar-expand '+t('resume.skill9.name')} ></span><em>{t('resume.skill9.name')}</em></li>
               <li key={t('resume.skill10.name')}><span style={{width:t('resume.skill10.level')}} className={'bar-expand '+t('resume.skill10.name')} ></span><em>{t('resume.skill10.name')}</em></li>
					</ul>
				</div>
			</div>
      </div>
   </section>
    );
  
}

export default Resume;
